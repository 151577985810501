import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from "@angular/forms";
import { SinglePrizeFormInterface } from "@formModels/create-round-prize-form-interface";
import { PredictionPrizeSectionService } from "../prize-section.service";
import { RoundPrizeTypeEnum } from "@enums/RoundPrizeTypeEnum";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RoundHelperService } from "../../../round-helper.service";
import { TextService } from "@services/text.service";

@Component({
  selector: 'prize-item',
  templateUrl: './prize-item.component.html',
  styleUrls: ['./prize-item.component.scss']
})
export class PrizeItemComponent  implements OnInit, OnDestroy {

  singlePrizeForm: FormGroup<SinglePrizeFormInterface>;

  @Input() index: number;

  isPrizeTypeDisplayed$ = new BehaviorSubject(false);

  prizeOptions = [
    {
      label: this.textService.createNameWithSpaceAndCapitalizeFirstLetter(RoundPrizeTypeEnum.CASH_POINTS),
      value: RoundPrizeTypeEnum.CASH_POINTS
    },
    {
      label: this.textService.createNameWithSpaceAndCapitalizeFirstLetter(RoundPrizeTypeEnum.LABEL),
      value: RoundPrizeTypeEnum.LABEL
    },
  ];

  protected readonly RoundPrizeTypeEnum = RoundPrizeTypeEnum;

  private unsubscribe$ = new Subject<void>()


  constructor(
    public predictionPrizeSectionService: PredictionPrizeSectionService,
    private rootFormGroup: FormGroupDirective,
    public roundHelperService: RoundHelperService,
    private textService: TextService,
  ) {}

  ngOnInit(): void {
    this.singlePrizeForm = this.rootFormGroup.control;

    this.predictionPrizeSectionService.roundPrizeType$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((type: RoundPrizeTypeEnum) => {
        if (type === RoundPrizeTypeEnum.LABEL || type === RoundPrizeTypeEnum.CASH_POINTS) {
          this.singlePrizeForm.get('type').patchValue(type);
        }
    })

    this.singlePrizeForm.get('type').valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((type) => {
        this.predictionPrizeSectionService.setMixTypeValidators(this.singlePrizeForm, type);
      })


  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  addNewPrizeLine() {
    this.predictionPrizeSectionService.addFormControl();
  }

  removePrizeLine() {
    this.predictionPrizeSectionService.removeFormControl(this.index);
  }

}
