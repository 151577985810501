<wizzard-section>
  <div header>QUESTIONS</div>
  <button
    button
    *ngIf="roundHelperService.roundStatus === RoundStatusEnum.COMPLETED || roundHelperService.roundStatus === RoundStatusEnum.RESULT_PROCESSING"
    (click)="undoSubmittedResults()"
    mat-raised-button
    color="primary">
    Undo submitted results
  </button>
  <div body>
    <ng-container *ngFor="let questionItem of correctAnswersForm?.get('questionsForm')?.controls; let i = index">
      <ng-container [ngSwitch]="questions[i].type">
        <ng-container [formGroup]="questionItem">
          <div class="question-answer-card" [ngClass]="{'not-matched': !questions[i].isMatched}">
            <right-score-answer
              *ngSwitchCase="QuestionType.SCORE"
              card-content
              [voidOptions]="voidTypeOptions"
              [isWarningNeed]="true"
              [_question]="questions[i]"
              [predictionNumber]="i + 1"
              [event]="questions[i].sportEvent">
            </right-score-answer>

            <right-score-answer
              *ngSwitchCase="QuestionType.SCORE_PLUS"
              card-content
              [isWarningNeed]="true"
              [voidOptions]="voidTypeOptions"
              [predictionNumber]="i + 1"
              [_question]="questions[i]"
              [event]="questions[i].sportEvent">
            </right-score-answer>

            <right-range-answer
              *ngSwitchCase="QuestionType.RANGE"
              card-content
              [isWarningNeed]="true"
              [voidOptions]="voidTypeOptions"
              [predictionNumber]="i + 1"
              [_question]="questions[i]"
            >
            </right-range-answer>

            <right-list-answer
              *ngSwitchDefault
              card-content
              [isWarningNeed]="true"
              [voidOptions]="voidTypeOptions"
              [predictionNumber]="i + 1"
              [_question]="questions[i]"
            >
            </right-list-answer>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</wizzard-section>
