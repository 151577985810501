import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import {  MatDialog } from '@angular/material/dialog';
import {
  BehaviorSubject, catchError,
  combineLatest,
  filter,
  firstValueFrom,
  lastValueFrom,
  map,
  merge,
  Subject, take,
  takeUntil,
  tap, throwError
} from 'rxjs';
import { RoundService } from "@services/round.service";
import { RoundProcessingService } from "@services/round-processing.service";
import { RoundTypesEnum } from "@enums/RoundTypesEnum";
import { TabsControllService } from "@services/tabs-controll.service";
import { TabIds, TabList, TabsEnum } from "@enums/TabsEnum";
import { RoundHelperService } from './round-helper.service';
import { DetailsFormSectionService } from './details-section-form.service';
import { FormGroup } from '@angular/forms';
import { CreateRoundMainDetailsFormInterface } from '../common/FormsModels/create-round-main-details-form-interface';
import { PredictionQuestionFormSectionService } from './prediction-question-section-form.service';
import { QuestionService } from '@services/question.service';
import { CreateRoundPrizeFormInterface } from "@formModels/create-round-prize-form-interface";
import { QuestionTypesEnum } from '@enums/QuestionTypesEnum';
import { QuestionDetailsModel } from "@models/QuestionDetailsModel";
import {
  CorrectAnswersDetailsFormInterface,
  EventDetailsFormInterface
} from "@formModels/correct-answers-details-form-interface";
import { CorrectAnswerPredictionServiceService } from './wizzard-sections/correct-answers-prediction-section/correct-answer-prediction-service.service';
import { QuestionTypeService } from './common-wizzard-components/prediction-question/question-type.service';
import { PredictionPrizeSectionService } from './wizzard-sections/prediction-prize-section/prize-section.service';
import { RounWizzardAnswerService } from './round-wizard-answer.service';
import { AnswerGroupsService } from './answer-groups.service';
import { RoundStatusEnum } from "@enums/RoundStatusEnum";
import { AnswerService } from "@services/answer.service";
import { RoundPrizeTypeEnum } from "@enums/RoundPrizeTypeEnum";
import { EventsService } from "@services/events.service";
import { EditCloseDateTimeModalComponent } from './edit-close-datetime-modal/edit-close-datetime-modal.component';
import { SnackBarService } from "@services/snack-bar.service";
import { IsCloseDateAfterOpen } from "./wizzard-sections/details-section/date-validator";
import { EditBonusModalComponent } from "@app/new-round-wizzard/edit-bonus-modal/edit-bonus-modal.component";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogService } from "@components/_base-component/dialog/dialog.service";
import { APP_ROUTES } from "@app/app.routes.config";

@Component({
  selector: 'round-wizzard',
  templateUrl: './round-wizzard.component.html',
  styleUrls: ['./round-wizzard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DetailsFormSectionService
  ]
})
export class RoundWizzardComponent implements OnDestroy, OnInit {

  private unsubscribe$: Subject<void> = new Subject();

  tabsEnum = TabsEnum;

  tabIds = TabIds;

  roundDetailsForm: FormGroup<CreateRoundMainDetailsFormInterface>;

  predictionQuestionForm: FormGroup;

  predictionPrizeForm: FormGroup<CreateRoundPrizeFormInterface>;

  questionTypes = QuestionTypesEnum;

  questionList$ = new BehaviorSubject<QuestionDetailsModel[]>(undefined);

  correctAnswersForm: FormGroup<CorrectAnswersDetailsFormInterface>;

  eventForm: FormGroup<EventDetailsFormInterface>;

  displayPredictionPrizeSection$ = new BehaviorSubject<boolean>(false);

  data = null;
  displayScoreSection$ = combineLatest([
    this.roundHelperService.isFinishedRound$,
    this.roundHelperService.isMultiEventRound$
  ]).pipe(
    map(([isFinishedRound, isMultiEventRound]) => isFinishedRound && !isMultiEventRound)
  );

  constructor(
    public roundService: RoundService,
    public roundHelperService: RoundHelperService,
    public roundProcessingService: RoundProcessingService,
    public tabsControllService: TabsControllService,
    public correctAnswerPredictionServiceService: CorrectAnswerPredictionServiceService,
    private questionTypeService: QuestionTypeService,
    private detailsFormSectionService: DetailsFormSectionService,
    private questionService: QuestionService,
    private predictionQuestionFormSectionService: PredictionQuestionFormSectionService,
    private predictionPrizeSectionService: PredictionPrizeSectionService,
    private rounWizzardAnswerService: RounWizzardAnswerService,
    private answerGroupService: AnswerGroupsService,
    private eventsService: EventsService,
    private dialog: MatDialog,
    private answerService: AnswerService,
    private snackbarService: SnackBarService,
    private isCloseDateAfterOpen: IsCloseDateAfterOpen,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.data = +this.route.snapshot.paramMap.get('id');
    this.tabsControllService.init([TabList[TabsEnum.DETAILS], TabList[TabsEnum.QUESTIONS]]);
    this.initData();
  }

  onBodyScroll(event: Event): void {
    this.tabsControllService.bodyScroll(event);
  }

  async initData() {
    let questionList = [];
    this.roundProcessingService.isLoading$.next(true);
    await firstValueFrom(this.eventsService.loadEvent());

    if (this.data) {
      // we need class of round for question why we load round before form geneartion
      await this.loadEditedRound(this.data);
      questionList = await lastValueFrom(
        this.questionService.getQuestionList(this.data)
      );
    }

    const isExternalAnswersAvailable = questionList.some(qustion => {
      return qustion.sportEvent?.externalData
    })

    this.roundProcessingService.isExternalAnswersAvailable$.next(
      !!this.roundHelperService.singleRoundEvent?.externalData || isExternalAnswersAvailable
    )

    // we need load answers before form
    await firstValueFrom(this.rounWizzardAnswerService.getAnswersList(null, isExternalAnswersAvailable));
    await firstValueFrom(this.answerGroupService.getAllAnswerGroups());

    this.initRoundDetailsForm();
    this.inirRoundWatchdog();

    this.initQuestionFormAndWatchdog();

    this.initPrizeForm();

    this.displayPredictionPrizeSection$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isDisplayed => {
        if (isDisplayed && !this.tabsControllService.isTabExist(TabList[TabsEnum.PRIZE])) {
          this.tabsControllService.addNewTab(TabList[TabsEnum.PRIZE]);
        } else if (!isDisplayed && this.tabsControllService.isTabExist(TabList[TabsEnum.PRIZE])) {
          this.tabsControllService.removeTab(TabList[TabsEnum.PRIZE]);
        }
      });

    this.displayScoreSection$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isDisplayed => {
        if (isDisplayed && !this.tabsControllService.isTabExist(TabList[TabsEnum.SCORE])) {
          this.tabsControllService.addNewTab(TabList[TabsEnum.SCORE]);
        } else if (!isDisplayed && this.tabsControllService.isTabExist(TabList[TabsEnum.SCORE])) {
          this.tabsControllService.removeTab(TabList[TabsEnum.SCORE]);
        }
      });

    if (this.data) {
      this.predictionPrizeSectionService.roundPrizeType$.next(this.roundHelperService.curentRound.prizeType)

      this.detailsFormSectionService.fillForm(
        this.roundDetailsForm,
        this.roundHelperService.curentRound
      );


      let matchedCheck = [];

      if (this.roundHelperService.roundStatus === RoundStatusEnum.COMPLETED || this.roundHelperService.roundStatus === RoundStatusEnum.RESULT_PROCESSING) {
        const correctAnswers = questionList.map(question => {
          return this.getAnswerCheckBody(question)
        }).filter(value => value);
        if (correctAnswers && correctAnswers.length) {
          matchedCheck = await firstValueFrom(
            this.answerService.checkAnswerMatches({correctAnswers})
              .pipe(map(response => response.questionIdsThatNotMatch))
          );
        }
      }
      this.predictionQuestionFormSectionService.fillQuestionArray(
        this.predictionQuestionForm,
        questionList,
        this.roundHelperService.isMultiEventRound,
        this.roundHelperService.roundStatus
      );

      this.correctAnswersForm = this.correctAnswerPredictionServiceService.formProcessing(questionList);

      if (! this.roundHelperService.isMultiEventRound) {
        this.eventForm = this.correctAnswerPredictionServiceService.buildEventForm(
          this.roundHelperService.sportEvent
        );
      }
      const modifiedQuestionList  = questionList.map((question: QuestionDetailsModel) => {
        if (!this.roundHelperService.isMultiEventRound && this.questionTypeService.isScoreQuestion(question.type)) {
          question.sportEvent = this.roundHelperService.sportEvent
        }
        question.isMatched = matchedCheck.length ? !matchedCheck.includes(question.id) : true;
        return question;
      });
      this.questionList$.next(modifiedQuestionList);
      this.predictionPrizeSectionService.processExistingPrize(
        this.roundHelperService.prize,
        this.roundHelperService.questions
      );
    } else {
      this.predictionPrizeSectionService.roundPrizeType$.next(RoundPrizeTypeEnum.CASH_POINTS);
    }

    this.predictionQuestionFormSectionService.questionChanges(this.predictionQuestionForm);
    this.roundProcessingService.isLoading$.next(false);
  }

  async loadEditedRound(roundId) {
    this.roundHelperService.curentRound = await lastValueFrom(
      this.roundService.getRoundById(roundId)
    );
    const isDownloadingAnswersAvailable = await firstValueFrom(this.roundProcessingService.isExternalAnswersAvailable$);
    this.roundProcessingService.isExternalAnswersAvailable$.next(isDownloadingAnswersAvailable);
  }

  getAnswerCheckBody (question) {
    const {id: questionId, correctAnswer: value, correctAnswerId: answerId, voidType} = question;
    if (!value && !answerId && !voidType) return null;
    if (value) {
      return {questionId, value}
    }
    if (answerId) {
      return {questionId, answerId}
    }
    if (voidType) {
      return {questionId, voidType}
    }
  }

  initPrizeForm() {
    this.predictionPrizeSectionService.processingPrizeForm();

    this.predictionPrizeForm = this.predictionPrizeSectionService.getForm();
  }

  initQuestionFormAndWatchdog() {
    this.predictionQuestionForm = this.predictionQuestionFormSectionService.build(
      this.roundHelperService.isMultiEventRound
    );

    this.predictionQuestionForm.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.displayPredictionPrizeSection$
          .next(this.predictionQuestionForm.getRawValue().questions.some(question => question.id))
      });
  }

  initRoundDetailsForm() {
    this.roundDetailsForm = this.detailsFormSectionService.buildForm();

    if (
      !this.roundHelperService.isMultiEventRound &&
      this.roundHelperService.singleRoundEvent
    ) {
      this.detailsFormSectionService.fillEventField(
        this.roundDetailsForm,
        this.roundHelperService.singleRoundEvent
      );
    }
  }

  inirRoundWatchdog() {
    merge(
      this.roundDetailsForm.get('openDate').valueChanges,
      this.roundDetailsForm.get('type').valueChanges,
      this.roundDetailsForm.get('closeDate').valueChanges
    )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.isCloseDateAfterOpen.roundDateErrorChecker(this.roundDetailsForm);
      });

    this.roundDetailsForm.get('type').valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(() => this.roundDetailsForm.dirty)
      )
      .subscribe((roundType: RoundTypesEnum) => {
        this.roundHelperService.roundType = roundType;
        this.predictionQuestionFormSectionService.changeFormQuestionTypeByRoundType(
          roundType,
          this.predictionQuestionForm,
          this.roundHelperService.isMultiEventRound
        );
        this.questionTypeService.changeQuestionType(roundType);
      });
  }

  async onSaveDraft() {
    await this.roundProcessingService.saveDraft(this.roundDetailsForm, this.predictionQuestionForm, this.data);
  }


  editRoundImage() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {closeDate, ...requestBody} = this.roundHelperService.curentRound;
    this.roundService.updateRoundsImageById(this.roundHelperService.curentRound.id,
      {
        round: {
        ...requestBody,
        questions: this.roundHelperService.curentRound.questions.map(item => item.id).filter(value => value),
        imageUrl: this.roundDetailsForm.value.imageUrl
      }
      })
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => this.snackbarService.showSnackBar('The image were updated successfully!')),
        catchError((error) => {
          return throwError(error);
        })
      )
      .subscribe()
  }

  async onPreviewAndPublish() {
    await this.roundProcessingService.previewAndPublish(this.roundDetailsForm, this.predictionQuestionForm, this.data);
  }

  onSubmitCorrectAnswers() {
    this.roundProcessingService.submitCorrectAnswers(this.data);
  }

  async onOpenEditCloseDate() {
    const ref = this.dialog.open(EditCloseDateTimeModalComponent, {
      data: {
        closeDate: this.roundHelperService.curentRound.closeDate
      }
    });
    const closeDate = await lastValueFrom(ref.afterClosed());
    if (closeDate) {
      this.roundDetailsForm.get('closeDate').patchValue(closeDate);
      await this.roundProcessingService.saveDraft(this.roundDetailsForm, this.predictionQuestionForm, this.data, true);
      await this.loadEditedRound(this.data);
      this.roundDetailsForm.enable();
      this.detailsFormSectionService.fillForm(
        this.roundDetailsForm,
        this.roundHelperService.curentRound,
        false
      );
    }
  }
  async onOpenEditBonus() {
    const ref = this.dialog.open(EditBonusModalComponent, {
      data: {
        bonus: this.roundHelperService.curentRound.bonus
      }
    });
    const bonus = await lastValueFrom(ref.afterClosed());
    if (bonus) {
      this.roundDetailsForm.get('bonus').patchValue(bonus);
      await this.roundProcessingService.saveDraft(this.roundDetailsForm, this.predictionQuestionForm, this.data, false, true);
      await this.loadEditedRound(this.data);
      this.roundDetailsForm.enable();
      this.detailsFormSectionService.fillForm(
        this.roundDetailsForm,
        this.roundHelperService.curentRound,
        false
      );
    }
  }

  backButtonClicked() {
    this.router.navigate(['v', APP_ROUTES.GAMES, APP_ROUTES.ALL_GAMES])
  }

  ngOnDestroy(): void {
    // need clean value before next using
    this.roundHelperService.roundClass = undefined;
    this.roundHelperService.singleRoundEvent = undefined;
    this.roundHelperService.curentRound = undefined;
    this.roundProcessingService.roundPublished$.next(false)
    this.roundProcessingService.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
