import {api} from "../assets/config/api.config";

export const environment = {
  production: false,
  version: "964",
  apiUrl: api.sts,
  name: "staging",
  sentryDsn:'https://9e61b937d01447168c34bc5ebac3b490@o1295285.ingest.sentry.io/6520598',
  isLocal: false
};
