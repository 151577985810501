<div class="edit-container">
  <div class="edit-header body-medium-medium">Edit Round Bonus</div>
  <div class="edit-body">
    <form [formGroup]="groupForm">
      <custom-input
        formControlName="bonus"
        placeholder="Bonus">
        Bonus
      </custom-input>
    </form>
  </div>
  <div class="buttons">
    <button
      id="stayButton"
      class="regular-button secondary-button"
      mat-stroked-button
      color="primary"
      mat-dialog-close>
      Cancel
    </button>
    <button
      class="regular-button"
      mat-flat-button
      color="primary"
      [disabled]="groupForm.invalid && groupForm.touched"
      (click)="save()">
      Save
    </button>
  </div>
</div>
