import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {TextService} from "./text.service";

@Injectable({
  providedIn: 'root'
})
export class CurrentLocationService {

  currentPage$ = new BehaviorSubject<string>('');

  currentChapter$ = new BehaviorSubject<string>('');

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private textService: TextService

  ) { }

  setCurrentPage (pageName) {
    this.currentPage$.next(pageName);
  }

  getCurrentPage () {
    return this.currentPage$;
  }

  setCurrentChapterName (chapterName) {
    this.currentChapter$.next(chapterName);
  }

  getCurrentChapterName () {
    return this.currentChapter$;
  }

  /**
   * Listen router changes and set current page and current chapter data
   */
  getCurrentLocation() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (this.activatedRoute.firstChild) {
          const segments = event.urlAfterRedirects.split('/').filter(item => item)
          if (segments.length > 2) {
            const chapterName = this.textService.createNameWithSpaces(segments[1]);
            this.setCurrentChapterName(chapterName);
          } else {
            this.setCurrentChapterName('');
          }
          if(!Number.isNaN(+segments[segments.length - 1]) && segments.length > 2) {
            const pagePath = `${this.textService.createNameWithSpaces(segments[segments.length - 2])} ${segments[segments.length - 1]}`;
            this.setCurrentPage(pagePath);
          } else {
            const pagePath = segments[segments.length - 1];
            this.setCurrentPage(pagePath);
          }
        } else {
          this.setCurrentPage('pagePath');
        }

      });
  }
}
