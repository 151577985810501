import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, of, tap } from "rxjs";
import { AnswerService } from '../core/services/answer.service';
import { AnswerDetailsModel } from '../common/models/AnswerDetailsModel';
import { RoundHelperService } from "./round-helper.service";
import { QuestionTypesEnum } from "../common/Enums/QuestionTypesEnum";
import { RoundProcessingService } from "../core/services/round-processing.service";
import { ExternalAnswersEnum } from "../common/Enums/ExternalAnswersEnum";

@Injectable()
export class RounWizzardAnswerService {

  _answerList$ = new BehaviorSubject<AnswerDetailsModel[]>(undefined);

  answerList$ = this._answerList$

  constructor(
    private answerService: AnswerService,
    private roundHelperService: RoundHelperService,
    private roundProcessingService: RoundProcessingService
  ) {
  }

  isExternalAnswerNeed(questionType) {
    return questionType === QuestionTypesEnum.LIST;
  }

  getAnswersList(externalEvent?, isExternalAnswersAvailable?) {
    const roundEvent = this.roundHelperService.singleRoundEvent;
    const currentEvent = externalEvent ? externalEvent : roundEvent;
    const sportEventExternalId = externalEvent ? externalEvent.externalData.eventId : (roundEvent && roundEvent.externalId) ? roundEvent.externalData?.eventId : null;
    const includeExternal = isExternalAnswersAvailable ? isExternalAnswersAvailable : this.roundProcessingService.isExternalAnswersAvailable$.value;
    const needDownloadExternal = this.roundProcessingService.isDownloadingAnswersAvailable(sportEventExternalId);
    return combineLatest([
      this.answerService.getAnswersList({includeExternal}),
      sportEventExternalId && needDownloadExternal ? this.answerService.getExternalAnswersList(sportEventExternalId) : of(null)
    ]).pipe(
        tap(([answerList, externalAnswerList]) => {
          const processedAnswerList = externalAnswerList ?
            this.filterExternalAnswers(answerList, this.getExternalAnswers(externalAnswerList, currentEvent), sportEventExternalId) :
            answerList;

          this._answerList$.next(processedAnswerList);
        })
    )
  }

  filterExternalAnswers(answerList, externalAnswerList, externalId) {
    const importedExternalAnswers = answerList.filter(answer => answer.isExternal);
    const fileteredExternalAnswerList = externalAnswerList.filter(externalAnswer => {
      return !importedExternalAnswers.some(importedExternalAnswer => {
        if (+importedExternalAnswer.externalData.eventId !== externalId) return false;
        return externalAnswer.id === importedExternalAnswer.externalData.outcomeId;
      });
    });
    return [...answerList, ...fileteredExternalAnswerList];
  }

  getExternalAnswers(externalAnswerList, externalEvent) {
    return externalAnswerList.map(answerItem => {
      const {text, externalData} = answerItem;
      const answerText = externalEvent ? this.getAnswerText(text, externalEvent) : text;
      const {outcomeId} = externalData;
      return {
        id: outcomeId,
        isExternalApi: true,
        text: answerText,
        externalData
      }
    });
  }

  deleteAnswersById(answer) {
    return this.answerService.deleteAnswersById(answer);
  }

  updateAnswer(body, id) {
    return this.answerService.updateAnswer(body, id);
  }

  createNewAnswers(answer) {
    return this.answerService.createNewAnswers(answer);
  }

  getAnswerText(answer, sportEvent) {
    if (sportEvent.externalId) {
      switch (answer.toUpperCase()) {
        case ExternalAnswersEnum.HOME:
          return sportEvent.homeTeamSportEvents.name;
        case ExternalAnswersEnum.AWAY:
          return sportEvent.awayTeamSportEvents.name;
        case ExternalAnswersEnum.DRAW:
          return 'Draw';
        default:
          return answer;
      }
    } else {
      return answer;
    }
  }
}
