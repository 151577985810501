import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoundWizzardComponent } from "@app/new-round-wizzard/round-wizzard.component";
import { APP_ROUTES } from "@app/app.routes.config";
import { RoundCreatingCanDeactivateGuard } from "@guards/round-creating.guard";

const routes: Routes = [
  {
    path: APP_ROUTES.CREATE_GAME,
    component: RoundWizzardComponent,
    canDeactivate: [RoundCreatingCanDeactivateGuard],

  },
  {
    path: `${APP_ROUTES.EDIT_GAME}/:id` ,
    component: RoundWizzardComponent,
    canDeactivate: [RoundCreatingCanDeactivateGuard],

  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoundWizzardRoutingModule {}
