import {  CanDeactivate } from '@angular/router';
import { Observable } from "rxjs";
import { RoundWizzardComponent } from "@app/new-round-wizzard/round-wizzard.component";
import { DialogService } from "@components/_base-component/dialog/dialog.service";
import { Injectable } from "@angular/core";
import { RoundProcessingService } from "@services/round-processing.service";

@Injectable({providedIn: 'root'})
export class RoundCreatingCanDeactivateGuard implements CanDeactivate<RoundWizzardComponent> {
  constructor(private dialogService: DialogService,
              private roundProcessingService: RoundProcessingService,) {}

  canDeactivate(component:RoundWizzardComponent): Observable<boolean> | boolean {
    if ( (component.roundDetailsForm?.touched ||
      component.predictionPrizeForm?.touched ||
      component.predictionQuestionForm?.touched ||
      component.correctAnswersForm?.touched ||
      component.eventForm?.touched)  && !this.roundProcessingService.roundPublished$.value) {
      return this.dialogService.navigateFromRoundCreateModalConfirmation()
    }
    return true;
  }
}
